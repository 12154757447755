var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-add-new-event","sidebar-class":"sidebar-lg","visible":_vm.isEventHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-event-handler-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.eventLocal.id ? "Actualizar" : "Registrar")+" Movimiento ")]),_c('div',[(_vm.eventLocal.id)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-event');
                            hide();}}}):_vm._e(),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Titulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Titulo","label-for":"event-title"}},[_c('b-form-input',{attrs:{"id":"event-title","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Titulo del Movimiento"},model:{value:(_vm.eventLocal.title),callback:function ($$v) {_vm.$set(_vm.eventLocal, "title", $$v)},expression:"eventLocal.title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Calendar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Centro de Trabajo","label-for":"centro_costo_id","state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL
                                        ? 'rtl'
                                        : 'ltr',"options":_vm.calendarOptions,"label":"label","reduce":function (calendar) { return calendar.label; },"input-id":"calendar"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                                        var color = ref.color;
                                        var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
                                        var color = ref.color;
                                        var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.eventLocal.extendedProps.calendar),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "calendar", $$v)},expression:"eventLocal.extendedProps.calendar"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Fecha del Movimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Fecha del Movimiento","label-for":"start-date","readonly":"","state":_vm.getValidationState(validationContext)}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                }},model:{value:(_vm.eventLocal.start),callback:function ($$v) {_vm.$set(_vm.eventLocal, "start", $$v)},expression:"eventLocal.start"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Fecha Fin Movimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Fecha Fin Movimiento","label-for":"end-date","state":_vm.getValidationState(validationContext)}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                }},model:{value:(_vm.eventLocal.end),callback:function ($$v) {_vm.$set(_vm.eventLocal, "end", $$v)},expression:"eventLocal.end"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.eventLocal.allDay),callback:function ($$v) {_vm.$set(_vm.eventLocal, "allDay", $$v)},expression:"eventLocal.allDay"}},[_vm._v(" Todo el dia ")])],1),_c('b-form-group',{attrs:{"label":"Agregar Empleados","label-for":"add-guests"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","close-on-select":false,"options":_vm.guestsOptions,"label":"name","input-id":"add-guests"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                                var avatar = ref.avatar;
                                var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
                                var avatar = ref.avatar;
                                var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.eventLocal.extendedProps.guests),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "guests", $$v)},expression:"eventLocal.extendedProps.guests"}})],1),_c('b-form-group',{attrs:{"label":"Location","label-for":"event-location"}},[_c('b-form-input',{attrs:{"id":"event-location","trim":"","placeholder":"Event Location"},model:{value:(_vm.eventLocal.extendedProps.location),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "location", $$v)},expression:"eventLocal.extendedProps.location"}})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"event-description"}},[_c('b-form-textarea',{attrs:{"id":"event-description"},model:{value:(_vm.eventLocal.extendedProps.description),callback:function ($$v) {_vm.$set(_vm.eventLocal.extendedProps, "description", $$v)},expression:"eventLocal.extendedProps.description"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.eventLocal.id ? "Update" : "Add ")+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }